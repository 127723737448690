import { ContactusComponent } from './../../rfh-home/contactus/contactus.component';
import { Injectable, HostListener, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import { TopmenuService } from '../../shared/services/topmenu.service';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor(private http: HttpClient, private toastrService: ToastrService ,public topmenuService: TopmenuService) { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	public topMenuFetch: any;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
		// console.log(this.screenWidth);
	}


	/*
   ---------------------------------------------
   ---------------  TOP MENU FETCH  -------------------
   ---------------------------------------------
 */

	// TOP Menu

	/**
	 *  Get Top Menu list
	 *
	 */

	getheadingMenu(): Observable<any> {
		return this.topMenuFetch = this.http.get(environment.baseUrl + 'Rfhhome/Gethomemenu');
		// Menu[] =  this.topMenuFetch;
		// Menu[] =  this.topMenuFetch.webHomeheadingmenu;
	}






	/*
   ---------------------------------------------
   ---------------  HEADER SUNDAY OPEN CLOSED TEXT FETCH  -------------------
   ---------------------------------------------
 */

	// TOP Menu

	/**
	 *  Get Header Sunday Open Closed Text Fetch
	 *
	 */

	getheadersundayopenclosedtext(): Observable<any> {
		return this.topMenuFetch = this.http.get(environment.baseUrl + 'Rfhhome/Getshoptimeingdetails');
	}





	/*
   ---------------------------------------------
   ---------------  LEFT  MENU FETCH  -------------------
   ---------------------------------------------
 */

	// LEFT Menu

	/**
	 *  Get Left Menu list
	 *
	 */

	getLeftMenu(): Observable<any> {
		return this.http.get(environment.baseUrl + 'Rfhhome/Getheadingmenu');
	}



	/*
   ---------------------------------------------
   --------------- PRODUCT PAGE PRODUCT ID WISE BANNER IMAGE FETCH  -------------------
   ---------------------------------------------
 */

	// Product Page Product ID wise Banner Image fetch

	/**
	 *  Get Banner image list
	 *
	 */

	getbannerImages(): Observable<any> {
		return this.http.get(environment.baseUrl + 'Rfhhome/Getbanner');
	}





	MENUITEMS: Menu[] = [
		{
			title: 'home', type: 'sub', active: false
		},
		{
			title: 'About Us', type: 'sub', active: false, children: [
				{ path: '', title: 'Who are we?', type: 'link' },
				{ path: '', title: 'Why RFH?', type: 'link' },
				{ path: '', title: 'Gallery', type: 'link' }
			]
		},
		{
			title: 'Products', type: 'sub', active: false, children: [
				{
					title: 'sidebar', type: 'sub', active: false
				},
				{ path: '/shop/product/three/column/trim-dress', title: 'Air Conditioners', type: 'link' },
				{ path: '/shop/product/four/image/belted-dress', title: 'Air Cooler', type: 'link' },
				{ path: '', title: 'Battery Fan', type: 'link' },
				{ path: '', title: 'Built-in Appliances', type: 'link' },
				{ path: '', title: 'Digital Inverters', type: 'link' },
				{ path: '', title: 'Electric Chimney', type: 'link' },
				{ path: '', title: 'Electric Fans', type: 'link' },
				{ path: '', title: 'Exhaust Fans', type: 'link' },
				{ path: '', title: 'Gas Stoves & Hobs', type: 'link' },
				{ path: '', title: 'Industrial Fans', type: 'link' },
				{ path: '', title: 'Kitchen Sinks', type: 'link' },
				{ path: '', title: 'Room Heaters', type: 'link' },
				{ path: '', title: 'Solar Water Heater', type: 'link' },
				{ path: '', title: 'Voltage Stabilizer', type: 'link' },
				{ path: '', title: 'Water Dispenser', type: 'link' },
				{ path: '', title: 'Water Heaters', type: 'link' }
			]
		},
		{
			title: 'EMI-Option', badge: true, badgeText: 'new', active: false
		},
		{
			path: '/home/contact', type: 'link', title: 'Contact Us', active: false
		},
		{
			title: 'Customer Care', type: 'sub', active: false
		},
		{
			title: 'Sunday Closed', type: 'sub', active: false
		}
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'Cooling Appliances', type: 'sub', megaMenu: true, active: false, children: [
			// title: 'Cooling Appliances', type: 'sub', active: false, children: [
				{
					title: 'Air Conditioners',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Window AC',  type: 'link' },
						{ path: '/home/fashion', title: 'Split AC',  type: 'link' }
					]
				},
				{
					title: 'Electric Fans',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Ceiling Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Table Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Wall Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Pedestal Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'All Purpose Fan',  type: 'link'},
						{ path: '/home/fashion', title: 'BLDC Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Tower Fan',  type: 'link' }
					]
				},
				{
					title: 'Battery Fan',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Table Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Pedestal Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Wall Fan',  type: 'link' }
					]
				},
				{
					title: 'Exhaust Fan',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Exhaust Fan',  type: 'link' }
					]
				},
				{
					title: 'Industrial Fan',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Air Circulator Pedestal Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Air Circulator Wall Fan',  type: 'link' },
						{ path: '/home/fashion', title: 'Heavy Duty Exhaust Fan',  type: 'link' }
					]
				},
				{
					title: 'Air cooler',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Air cooler',  type: 'link' }
					]
				},
				{
					title: 'Air Curtain',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Air Curtain',  type: 'link' }
					]
				},
				{
					title: 'Air Purifier',  type: 'link', active: false, children: [
						{ path: '/home/fashion', title: 'Air Purifier',  type: 'link' }
					]
				},
			]
		},
		{
			title: 'Heating Appliances', type: 'sub', megaMenu: true, active: false, children: [
				{
					title: 'Water Heater',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Storage Heater',  type: 'link' },
						{ path: '/home/fashion', title: 'Instant Geyser',  type: 'link' },
						{ path: '/home/fashion', title: 'Gas Heaters',  type: 'link' }
	
					]},
				{
					title: 'Room Heater',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Heat Convectors',  type: 'link' }
	
					]},
				{
					title: 'Solar Water Heater',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Heat Convectors',  type: 'link' },
						{ path: '/home/fashion', title: 'Pressure Type',  type: 'link' },
						{ path: '/home/fashion', title: 'Regular Type',  type: 'link' }
	
					]},
				{
					title: 'Microwave Oven',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Storage Heater',  type: 'link' },
						{ path: '/home/fashion', title: 'Instant Geyser',  type: 'link' },
						{ path: '/home/fashion', title: 'Gas Heaters',  type: 'link' }
	
					]}
	
	
			]
		},
		{
			title: 'Kitchen Appliances', type: 'sub', megaMenu:true, active: false, children: [
				{
					title: 'Electric Chimney',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Decorative Chimney',  type: 'link' },
						{ path: '/home/fashion', title: 'Traditional Chimney',  type: 'link' }
	
					]},
				{
					title: 'Gas Stove & Hobbs',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Built in Hobbs',  type: 'link' },
						{ path: '/home/fashion', title: 'Gas Stoves',  type: 'link' }
	
					]},
				{
					title: 'Dishwasher',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Standing',  type: 'link' }
	
					]},
				{
					title: 'Kitchen Sinks',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Regular',  type: 'link' }
	
					]},
				{
					title: 'Build in Appliances',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Built In Range',  type: 'link' },
						{ path: '/home/fashion', title: 'Oven Toaster Griller - OTG',  type: 'link' }
	
					]},
			]
		},
	
		{
			title: 'Home Appliances', type: 'sub', megaMenu:true, active: false, children: [
				{
					title: 'Digital Inverter',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'UPS Inverters',  type: 'link' }
	
					]},
				{
					title: 'Voltage Stablizer',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'AC Stablizer',  type: 'link' },
						{ path: '/home/fashion', title: 'Appliance Stablizer',  type: 'link' }
	
					]},
				{
					title: 'Battery',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Inverter Battery',  type: 'link' }
	
					]},
	
			]
		},
		{
			title: 'Purification', type: 'sub', megaMenu:true, active: false, children: [
				{
					title: 'Water Purifier',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Water Purifier',  type: 'link' }
	
					]},
			]
		},
		{
			title: 'Other Appliances', type: 'sub', megaMenu:true, active: false, children: [
				{
					title: 'Remote Control',  type: 'link', active: false, children: [
	
						{ path: '/home/fashion', title: 'Remote Control',  type: 'link' }
	
					]},
			]
		}
	];
	
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
	
	}
