import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(private http: HttpClient,
              private toastrService: ToastrService) { }


  /*
 ---------------------------------------------
 ---------------  TOP COLLECTION IMAGES FETCH  -------------------
 ---------------------------------------------
*/

  // Top Collection Images Fetch

  /**
   *  Get Home page top collection images
   *
   */

  getTopCollectionImages(): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/Gettopcollectiondetails');
  }


  /*
 ---------------------------------------------
 ---------------  HOME PAGE BRAND IMAGE FETCH  -------------------
 ---------------------------------------------
*/

  // BRAND IMAGE Fetch

  /**
   *  Get Home page BRAND IMAGE list
   *
   */

  getHomepageBrandImageList(): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/Gettopimagedetails');
  }


  /*
 ---------------------------------------------
 ---------------  FOOTER ADDRESS  FETCH  -------------------
 ---------------------------------------------
*/

  // FOOTER ADDRESS Fetch

  /**
   *  Get Footer address fetch
   *
   */

  getFooterAddressFetch(): Observable<any> {
    return this.http.get(environment.baseUrl + 'Rfhhome/Getstoreinformation');
  }

  /*
 ---------------------------------------------------------------------
 ---------------  AFTER PAYMENT SUCCESS DATA FETCH  -------------------
 -----------------------------------------------------------------------
*/

  // AFTER PAYMENT SUCCESS DATA FETCH

  /**
   *  After payment success data fetch
   *
   */

  getPaymentSuccessFetchdata(param): Observable<any> {
    // console.log(param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/getonlinesuccessinformationdetails?TransactionId=' + param);
      return this.http.post(environment.baseUrl + 'Rfhhome/getonlinesuccessinformationdetails', param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getonlinesuccessinformationdetails?TransactionId=4ef3818b6e6f422da378c4b8a3b42d
  }

 /*
 ---------------------------------------------------------------------
 ---------------  AFTER PAYMENT SUCCESS MAIL SEND  -------------------
 -----------------------------------------------------------------------
*/

  // AFTER PAYMENT SUCCESS MAIL SEND

  /**
   *  After payment success mail send
   *
   */

  successmailsend(param): Observable<any> {
    // console.log(param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/successmailsend?Trackid=' + param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/successmailsend?Trackid=' + param.Trackid + '&emailid=' + param.emailid);
      return this.http.post(environment.baseUrl + 'Rfhhome/successmailsend', param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/successmailsend?Trackid=4ef3818b6e6f422da378c4b8a3b42d
  }



  /*
 ---------------------------------------------------------------------
 ---------------  AFTER PAYMENT  DATA DELETE  -------------------
 -----------------------------------------------------------------------
*/

  // AFTER PAYMENT  DATA delete

  /**
   *  After payment  data delete
   *
   */

  getPaymentDataDelete(param): Observable<any> {
    // console.log(param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/updatedeleteaddtocard?productdetails=' + param);
      return this.http.post(environment.baseUrl + 'Rfhhome/updatedeleteaddtocard', param);
    //https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/updatedeleteaddtocard?productdetails=37862,1357182,42fb5b0e68b94c30b0f15ba0d5eb30,success;31732,1357182,42fb5b0e68b94c30b0f15ba0d5eb30,success;
  }


  /*
 ---------------------------------------------
 -------------  BRAND NAME FETCH  ------------
 ---------------------------------------------
*/

getBrandNameList(): Observable<any> {
  return this.http.get(environment.baseUrl + "Rfhhome/Getcustomercarebranddetails");
}


/*
    ---------------------------------------------
    ---------- ccare branddetailsFetch  ---------
    ---------------------------------------------
  */

     getBrandNameValues(brandid): Observable<any> {
      // return this.http.get(environment.baseUrl + 'Rfhhome/Getcustomercareinformation?BrandId=' + brandid );
         return this.http.post(environment.baseUrl + 'Rfhhome/Getcustomercareinformation', brandid);

    }

/*
    ---------------------------------------------------------
    ---------- MYINFO PAGE ORDER HISTORY LIST FETCH  ---------
    ----------------------------------------------------------
  */

     getOnlinePurchaseHistoryView(param): Observable<any> {
      // return this.http.get(environment.baseUrl + 'Rfhhome/getonlinepurchasehistoryview?Fromdate=' + param.Fromdate+ '&Todate=' + param.Todate+ '&CustomerId=' + param.CustomerId);
         return this.http.post(environment.baseUrl + 'Rfhhome/getonlinepurchasehistoryview', param);
      // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getonlinepurchasehistoryview?Fromdate=0&Todate=0&CustomerId=1357182
    }



/*
    ---------------------------------------------------------
    ---------- MYINFO PAGE DESCRIPTION VIEW PART  ---------
    ---------------------------------------------------------
  */

     getOnlineDescriptionViews(param): Observable<any> {
      // return this.http.get(environment.baseUrl + 'Rfhhome/getonlineDescriptionsviews?EntityID=' + param.EntityID+ '&TransactionID=' + param.TransactionID+ '&ProductId=' + param.ProductId+ '&BrandID=' + param.BrandID);
      return this.http.post(environment.baseUrl + 'Rfhhome/getonlineDescriptionsviews', param);
      // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/getonlineDescriptionsviews?EntityID=1&TransactionID=63741b82af65c48bf4cb&ProductId=1&BrandID=5
    }



/*
    ---------------------------------------------------------
    ---------- URL CUSTOMER REVIEW FETCH DATA VIEW PART  ---------
    ---------------------------------------------------------
  */

    Getcustomerpurchasedetails(param): Observable<any> {
      return this.http.post(environment.baseUrl + 'Rfhhome/Getcustomerpurchasedetails', param);
      // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Getcustomerpurchasedetails
    }


/*
    ---------------------------------------------------------
    ---------- URL CUSTOMER REVIEW FETCH DATA SUBMIT PART  ---------
    ---------------------------------------------------------
  */

    insertuserproductdfeedback(param): Observable<any> {
      return this.http.post(environment.baseUrl + 'Rfhhome/insertuserproductdfeedback', param);
      // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/Getcustomerpurchasedetails
    }




/*
    ----------------------------------------------------------------------
    ---------- URL CUSTOMER REVIEW PAGE SUBMIT INVOICE  DETAILS ----------
    -----------------------------------------------------------------------
  */

    einvoice(param): Observable<any> {
        // console.log(param);
      return this.http.post(environment.baseUrl + 'Rfhhome/einvoice', param);
    }














}
