<!--<ngx-loading-bar></ngx-loading-bar>-->

<!--<ngx-spinner type="ball-scale-multiple"></ngx-spinner>-->

<!--<ngx-spinner-->
<!--        bdColor="rgba(51, 51, 51, 0.8)"-->
<!--        color="#fff"-->
<!--        size="large"-->
<!--        type="ball-atom"></ngx-spinner>-->


<ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="large"
        color="#fff"
        type="ball-atom">
    <!-- <p style="font-size: 20px; color: white">Loading...</p> -->
</ngx-spinner>

<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<app-tawk></app-tawk>
<app-layout-box></app-layout-box>
