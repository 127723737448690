import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
// import { HomeComponent } from '../../../rfh-home/rfh-home.module';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;

  selectedCategory: string;

  public green_active_split : boolean = false;

  public green_active_window : boolean = false;

  public category1 = '';
  public category2 = [];

  constructor(private actroting: ActivatedRoute, private router: Router , public productService: ProductService) {
    this.productService.getProducts.subscribe(product => this.products = product);
    // console.log("Cateogry PAAAA",this.products);
  }

  ngOnInit(): void {

    // console.log("34324324cffd dsfdsfdsf",this.products);
    //
    // this.category1 = ''+this.products[0].webproductid;
    //
    // console.log("111111111",this.category1);

  }

 

  get filterbyCategory() {
    // console.log(this.products);
    // console.log(JSON.parse(localStorage["brandList"]));
    // const category = [...new Set(this.products.map(product => product.type))]

    // if(this.products) {
      // this.category1 = '' + this.products[0].webproductid;
    // }


    // console.log("222222",this.category1);

    const category = [...new Set(this.products.map(product => product.category))]

    // setTimeout(() => {
    //   this.category1 = ''+this.products[0].webproductid;
    // }, 500);

    // this.category1 = ''+[...new Set(this.products.map(product => product[0].webproductid))];
    this.category2 = [...new Set(this.products.map(product => product.webproductid))];

    this.category1 = ''+this.category2[0];


    // console.log("222222sdfdsfdfdsfds",this.category1);
    // console.log("222222",this.category2);
    // // console.log("222222",this.category1[0]);
    // console.log("222222",this.category2[0]);

    // console.log(category)
    // console.log(this.products.map(product => product.type));
    // console.log(this.products.map(product => product.category));
    // console.log(this.products.map(product => product));
    // console.log(this.products.map(product => product.id));
    // console.log(this.products.map(product => product.webproductid));
    return category
  }

}
