import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import {Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() compareshwchk: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string


  public products: Product[] = [];

  public products1: Product[] = [];

  @Input() product1: Product;

  public addcomparray = [];

  constructor(private productService: ProductService , private router: Router,
              private toastrService: ToastrService) {

    this.productService.compareItems.subscribe(response => this.products1 = response);

    // console.log("LIST-ProductONE",this.products1);
    // console.log("LENGTH-ProductONE",this.products1.length);
  }

  ngOnInit(): void {
    if(this.loader) {
      // setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
      setTimeout(() => { this.loader = false; }, 5000); // Skeleton Loader
    }
    // console.log("ProductONE",this.product);
    // console.log("ProductONE",this.product.wepbproductid);
    // localStorage['prodcateogry'] = this.product.wepbproductid;
    // console.log(localStorage['prodcateogry']);
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);

    // console.log(this.addcomparray);
    // const addcomparray = [];
    this.addcomparray.push(product.pdid,product.webproductid);

    // console.log(this.addcomparray);
    // console.log("PDID",product.pdid);
    // console.log("WEBPRODUCTID",product.webproductid);

  }


  productidFetch(product){
    localStorage.removeItem("prodcateogry");
      // console.log(product);
      // console.log(localStorage['prodcateogry']);
    // localStorage['prodcateogry'] = product.wepbproductid;
    localStorage['prodcateogry'] = product.webproductid;
    // console.log(localStorage['prodcateogry']);
    // this.[routerLink]="['/shop/product/left/sidebar/',product?.title.replace(' ', '-')]";
    // console.log("tittle", this.product.title);
    // console.log("URL", '/shop/product/left/sidebar/',product?.title.replace(' ', '-'));
    // this.router.navigate(['/shop/product/left/sidebar/',product?.title.replace(' ', '-')]);
    this.router.navigate(['/shop/product/left/sidebar/'+product.webproductid+'/',product?.title.replace(' ', '-')]);
    // console.log("4rr4r4e4", this.product);
    // Get Filtered Products..
    this.productService.filterProducts('').subscribe(response => {
      // console.log("PRODUCTID FETCH");
      // Sorting Filter
      // console.log("RESPONSE4444444444444444",response);
      // this.products = this.productService.sortProducts(response,'');
      // console.log("RESPONSE555555555",this.products);
    })

  }


  isAllSelected(checked, item , pdid) {
        // console.log(checked);
        // console.log(item);
        // console.log(checked.target);
    //     console.log(item.pdid);
    //     console.log(item.BrandName);

      // console.log(this.product);
      // console.log(this.products1);
      // console.log(this.products1.length);


    // console.log("BRANDDD2",this.addcomparray1);
    // let index = this.addcomparray1.indexOf(item);  // checked and unchecked value
    let index = this.products1.indexOf(item);  // checked and unchecked value
    // console.log("INDEXNNNNN",index);
    if (checked) {
      // console.log("STEP1");
      if(this.products1.length < 3) {
        // this.addcomparray1.push(item); // push in array cheked value
        this.productService.addToCompare(item);
      }else{
        // console.log("STEP3");
        // alert("Already you choose three product");
        this.toastrService.error('Already you choose three product.');
        // this.addcomparray1.splice(index, 1);  // removed in array unchecked value
        $('#valuechk'+item.pdid).prop('checked', false);
      }
    }else {
      // console.log("STEP2");
      // this.addcomparray1.splice(index, 1);  // removed in array unchecked value
      this.products1.forEach((val, index) => {
        // console.log(index);
        // console.log(val);
        // setTimeout(this.productService.removeCompareItem(val,'0'),)
        if(val.pdid == item.pdid){
          // console.log(index);
          this.productService.removeCompareItem(item,"" +index);
        }
      });
    }
    // console.log("BRANDDD2",this.addcomparray1);
    // console.log("C1111111",localStorage["compareItems"]);
    // console.log("BRANDDD2",this.addcomparray2);
    // console.log(this.products1);
  }



}
