import { Component, OnInit, Input } from '@angular/core';
import { HomepageService} from "../../services/homepage.service"

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  public storeinformation = [];

  constructor(public homepageservice: HomepageService) { }

  ngOnInit(): void {


    /**
     *  Get FOOTER Address Fetch in homepage services page
     *
     */

    this.homepageservice.getFooterAddressFetch().subscribe(datafetch => {
      // console.log(datafetch);
      // console.log(datafetch.storedetails);
      this.storeinformation = datafetch.storedetails;
    });


  }

}
