import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

declare var $;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService , private sanitizer: DomSanitizer) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    // console.log("menu items", this.menuItems);

    this.navServices.getheadingMenu().subscribe(datanew1 => {
      // console.log(datanew1);
      // console.log(datanew1.Headingmenu);
      // console.log(datanew1.subWebCategorygmenu);
      // console.log(datanew1.subheadingmenu);
      // console.log(datanew1.webHomeheadingmenu);
      this.menuItems = datanew1.webHomeheadingmenu;
      // console.log("menu items11111", this.menuItems);

      // Headingmenu
      //     :
      //     [{ApplianceId: 1, ApplianceName: "Cooling Appliances"},…]
      // subWebCategorygmenu
      //     :
      //     [{webproductid: 1, WebCategoryid: 4, WebCategoryName: "Window AC"},…]
      // subheadingmenu
      //     :
      //     [{webproductid: 1, webproductname: "Air Conditioners", subApplianceId: 1},…]
      // webHomeheadingmenu
      //     :
      //     null

    });
  }

  ngOnInit(): void {

  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // decodeHtml(html) {
  //   console.log(html);
  //   const txt = document.createElement("textarea");
  //   txt.innerHTML = html;
  //   console.log(txt.value);
  //   return txt.value;
  // }

  decodeEntities(str) {
    // // console.log(str);
    // // this prevents any overhead from creating the object each time
    // const element = document.createElement('div');
    // if(str && typeof str === 'string') {
    //   // strip script/html tags
    //   str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    //   str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    //   str = str.replace(/%40/gi, '@');
    //   str = str.replace(/%3A/gi, ':');
    //   str = str.replace(/%24/gi, '$');
    //   str = str.replace(/%2C/gi, ',');
    //   str = str.replace(/%3B/gi, ';');
    //   str = str.replace(/%2B/gi, '+');
    //   str = str.replace(/%3D/gi, '=');
    //   str = str.replace(/%3F/gi, '?');
    //   str = str.replace(/%2F/gi, '/');
    //   element.innerHTML = str;
    //   str = element.textContent;
    //   element.textContent = '';
    // }

    const decodeURL = decodeURIComponent(str);
    // console.log(decodeURL);

    return decodeURL;

    // // let url = this.router.url;
    // let url = str;
    // url = url.replace(/%40/gi, '@')
    //     .replace(/%3A/gi, ':')
    //     .replace(/%24/gi, '$')
    //     .replace(/%2C/gi, ',')
    //     .replace(/%3B/gi, ';')
    //     .replace(/%2B/gi, '+')
    //     .replace(/%3D/gi, '=')
    //     .replace(/%3F/gi, '?')
    //     .replace(/%2F/gi, '/');
    // // if(url !== this.router.url){
    // //   this.router.navigate([url]);
    // // }
    // console.log(url);

    // console.log(str);
    // return str;
  }

  menuclick(val){
    return this.decodeEntities(val);

  }


  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    // console.log("TOGGLENATIVE",item);
    // console.log("TOGGLENATIVE",item.websitemenuid);
    // console.log("TOGGLENATIVE",item.active);
    item.active = !item.active;
    // // submenuid
    // //     :
    // //     "16"
    // // submenuname
    // //     :
    // //     "Built-i
    // console.log(item.submenuid);
    // console.log(item.submenuname);
    // // console.log(['/shop/collection/left/sidebar/' + item.submenuname + item.submenuid ]);
    // console.log(['/shop/collection/left/sidebar?category=' + item.submenuname + '&id=' + item.submenuid]);
    // // console.log([queryParams]="{ category: collection.Name,id:collection.id});
    // // this.router.navigate(['/shop/collection/left/sidebar/'category: collection.Name,id:collection.id]);
    //
    // // this.router.navigate(['/shop/collection/left/sidebar?category='+ item.submenuname+'&id='+ item.submenuid]);
    //
    //
    // // this.router.navigate(['/shop/collection/left/sidebar/' + item.submenuname + item.submenuid ]);
    // // this.router.navigate(['/shop/collection/left/sidebar/']);
    // // console.log('/shop/collection/left/sidebar?category='+ item.submenuname+'&id='+ item.submenuid);
    // //
    // // const newww = (this.decodeEntities(this.router.navigate(['/shop/collection/left/sidebar/' + item.submenuname + item.submenuid ])));
    // // console.log("RRRRR", newww);
    //
    // // return this.sanitizer.bypassSecurityTrustResourceUrl(this.router.navigate(['/shop/collection/left/sidebar/' + item.submenuname + item.submenuid ]));
    //
    // if(item.websitemenuid == 1){
    //   // console.log("HOME PAGE");
    //   this.router.navigate(['/home/']);
    // }
    // if(item.websitemenuid == 4){
    //   // console.log("EMI PAGE");
    //   this.router.navigate(['/home/emi/']);
    // }
    //
    // if(item.websitemenuid == 6){
    //   // console.log("Contactuspage");
    //   this.router.navigate(['/home/contact/']);
    // }
    //
    // if(item.websitemenuid == 7){
    //   // console.log("customercarePAge");
    //   this.router.navigate(['/home/ccare/']);
    // }
  }

  menuchecck(item){
    // console.log(item);
    const menuchkk = ['Gallery','Who are We?','Why Rfh?','Why Rfh - About Us'];
    // let index = menuchkk.indexOf( "Gallery" );
    return menuchkk.indexOf(item.submenuname);

  }

}
