import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private toastrService: ToastrService,public router:Router) { }


  /*
  ----------------------------------------------------------
  ---------------  USER REGISTRATION DETAILS SAVE  --------------------
  -----------------------------------------------------------
 */

  // USER REGISTRATION DETAILS SAVE

  /**
   *  Get User Registration Details Save
   *
   */

  userRegistrationSave(param): Observable<any> {
    // console.log(param);
    // Frontend
    // email:"test@gmail.com"
    // gender:"Male"
    // landlineno:"044121234"
    // mobileno:"1234567890"
    // name:"test"
    // password:"12345"
    // Backend
    // registration.custname = custname;
    // registration.Password = Encrypt(Password);
    // registration.Mobileno = Mobileno;
    // registration.Phoneno = Phoneno;
    // registration.gender = gender;
    // registration.EmailId = EmailId;
    // registration.chknews = chknews;
    // return this.http.get(environment.baseUrl + 'Rfhhome/InsertregistrationDetails?custname='+ param.name+
    //     '&Password=' + param.password+
    //     '&Mobileno=' + param.mobileno+
    //     '&Phoneno=' + param.landlineno+
    //     '&gender=' + param.gender+
    //     '&EmailId=' + param.email+
    //     '&chknews=' + 'N');
    return this.http.post(environment.baseUrl + 'Rfhhome/InsertregistrationDetails', param);

  }


  /*
  ----------------------------------------------------------
  ---------------  LOGIN DETAILS CHECK  --------------------
  -----------------------------------------------------------
 */

  // LOGIN DETAILS CHECK

  /**
   *  Get Login Details Check
   *
   */

  loginDetailsCheck(param): Observable<any> {
    // console.log(param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/GetUserLogin?Username='+ param.userName+ '&Password=' +
    //     param.password);
    //http://localhost:53623/api/Rfhhome/create?
    return this.http.post(environment.baseUrl + 'Rfhhome/GetUserLogin', param);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetUserLogin?Username=kanagaraj.sk@naethra.com&Password=kanagu123

  }


  /*
  ----------------------------------------------------------
  ---------------  LOGIN USER TOKEN ID FETCH  --------------------
  -----------------------------------------------------------
 */

  // LOGIN USER DETAILS TOKEN ID FETCH

  /**
   *  Get Login User Details token id Fetch
   *
   */

  tokenIdFetch(param): Observable<any> {
    // console.log(param);
    return this.http.post(environment.baseUrl + 'Rfhhome/key', param);
    // http://localhost:53623/api/Rfhhome/key
  }



  /*
  ----------------------------------------------------------
  ---------------  LOGIN USER DETAILS FETCH  --------------------
  -----------------------------------------------------------
 */

  // LOGIN USER DETAILS FETCH

  /**
   *  Get Login User Details Fetch
   *
   */

  loginUserDetailsFetch(param): Observable<any> {
    // console.log(param);
    return this.http.post(environment.baseUrl + 'Rfhhome/GetUserlogindetails' , param);
    // http://localhost:53623/api/Rfhhome/Userdetails
    // // return this.http.get(environment.baseUrl + 'Rfhhome/GetUserlogindetails?emailid='+ param.mailid);
    // // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/GetUserlogindetails?emailid=test1@gmail.com
  }





 /*
 ----------------------------------------------------------
 ---------------  FORGOT PASSWORD DETAILS CHECK  --------------------
 -----------------------------------------------------------
*/

  // FORGOT PASSWORD CHECK

  /**
   *  Get Forgot Password Check
   *
   */

  forgotPasswordchk(param): Observable<any> {
    // console.log(param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/Getforgetpassword?emailid='+ param.email+ '&random=' +
    //     param.random);
    return this.http.post(environment.baseUrl + 'Rfhhome/Getforgetpassword' , param);

  }



 /*
 ----------------------------------------------------------
 ---------------  CHANGE PASSWORD DETAILS SAVE  --------------------
 -----------------------------------------------------------
*/

  // CHANGE PASSWORD DETAILS SAVE

  /**
   *  Change Password Details Save
   *
   */

  changePasswordSave(param): Observable<any> {
    // console.log(param);
    return this.http.post(environment.baseUrl + 'Rfhhome/ChangePassword', param);
    // return this.http.get(environment.baseUrl + 'Rfhhome/ChangePassword?emailid='+ param.emailid+ '&password=' +
    //     param.password+ '&random=' + param.random);
    // https://rathnafanhouse.co.in/rfhwebapi/api/Rfhhome/ChangePassword?emailid=kanaguselva55@gmail.com&password=123444&random=dfghhh
  }

  isAuthenticated(){
    // console.log('sessionStorage',sessionStorage.getItem('token'));
    // console.log('localStorage',localStorage.getItem('token'));
     if (localStorage.getItem('token') !==null) {
      return true;
    }
    return false;
  }

  canAuthenticate(val){
    // console.log(val);
    // console.log("localStorage",localStorage);
    // console.log('this is printed from authentication.sevice.ts');
    if(!val) {
      if (this.isAuthenticated()) {
        this.router.navigate(['/home']);
      }
    }
    
  }


}
