import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TopmenuService {

  constructor(private http: HttpClient,
              private toastrService: ToastrService) { }


  /*
    ---------------------------------------------
    ---------------  TOP MENU FETCH  -------------------
    ---------------------------------------------
  */

  // TOP Menu

  /**
   *  Get All Lead list in Lead list view page
   *
   */



  getheadingMenu(): Observable<any> {
    // let reqOpts: any = {};
    // reqOpts = params;
    return this.http.get(environment.baseUrl + 'Rfhhome/Getheadingmenu');
  }



}
