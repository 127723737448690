import { Component, OnInit, Input, HostListener,ChangeDetectorRef  } from '@angular/core';
import {NavService} from "../../services/nav.service";
import {ActivatedRoute, Router} from "@angular/router";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import {TranslateService} from "@ngx-translate/core";
import { FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

    public getSundayopenclosedText = [];
    public getmarqueeText = '';
    public products: Product[] = [];

    cusId = '';
    profileUsername ='';

    public searchForm: FormGroup;

  constructor(private route: ActivatedRoute,public navServices: NavService , private router: Router,
              public fb: FormBuilder, public productService: ProductService ,private toastr: ToastrService,private cdRef: ChangeDetectorRef ) {
      this.productService.cartItems.subscribe(response => this.products = response);
      // console.log("Header page ", this.products);

      /**
       *  Get header Sunday open closed text fetch  in homepage services page
       *
       */

      this.navServices.getheadersundayopenclosedtext().subscribe(datafetch => {
          // console.log(datafetch);
          // console.log(datafetch.shoptimedetails);
          this.getSundayopenclosedText = datafetch.shoptimedetails;
          this.getmarqueeText = datafetch.shoptimedetails[0].shopemarqueetext;
          // this.getmarqueeText = '';
          // console.log(this.getmarqueeText);
      });

    this.cusId = localStorage["customerid"];
    this.profileUsername = localStorage["username"];
    // console.log("headerCUSTOMERID",localStorage["customerid"]);
    // console.log("headerUSERNAME",localStorage["username"]);
    // console.log("CUSID HEADER PAGE",this.cusId);
  }

  ngOnInit(): void {
      this.initFormGroup();
      this.route.queryParams.subscribe(params => {
          // console.log("headersrchtxt",params);
          // console.log("headersrchtxt",params.id);
          // console.log("headersrchtxt1111111",params.search);
          if(params.search){
              this.searchForm.patchValue({
                  searchtxt: params.search,
              });
          }
      });
  }
    /**
     Login form group initizise
     */
    initFormGroup() {
        this.searchForm = this.fb.group({
            searchtxt: new FormControl(''),
        });

    }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

    onLogOut(){
        // localStorage["cartItems"] = '';
        // localStorage["customerid"] = [];
        // localStorage["username"] = [];

        // this.products.forEach((val, index) => {
        //     console.log(val);
        //     this.productService.removeCartItem(val);
        // });
        // console.log(this.products);
        // this.products = [];
        // localStorage.clear();
        // alert('Logout successfully.');

        // console.log(this.products);
        // console.log(this.products.length);
        this.products.forEach((val, index) => {
            // console.log(index);
            // console.log(val);
            // setTimeout(this.productService.removeCartItem(val,'0'),)
            setTimeout(() => {this.productService.removeCartItem(val,'0')},1000);
        });
        // console.log('localStorage',localStorage);
        localStorage.clear();
        this.profileUsername='';
        this.toastr.success("Logged out successfully ");
        this.router.navigate(['/home']);
        this.cdRef.detectChanges(); // Trigger change detection

        // console.log('localStorage',localStorage);

        location.reload();

        // this.router.routeReuseStrategy.shouldReuseRoute = function () { return false }
        // const currentUrl = this.router.url + '?'
        // console.log("CUUREENTURL",currentUrl);
        // return this.router.navigateByUrl(currentUrl).then(() => {
        //     this.router.navigated = false
        //     this.router.navigate([this.router.url])
        // })

        // console.log("LOGOUTcusid",localStorage["customerid"]);
        // console.log("LOGOUTusrname",localStorage["username"]);
        // console.log("CART ITEMS",localStorage["cartItems"]);
        // this.router.navigate(['/home']);
        // window.location.reload();
        // window.location.replace('/home');
    }


    /**
     *    Search product fetch
     *
     */
    searchText(){
        // console.log("SEARCH TEXT");
        // console.log(this.searchForm.value);

        // this.router.navigate(['/shop/collection/left/sidebar/productid=-1&search='+ this.searchForm.value.searchtxt])
        // [routerLink]="['/shop/collection/left/sidebar/']" [queryParams]="{ category: childrenItem.submenuname,id:childrenItem.submenuid}">

        this.router.navigate([ '/shop/collection/left/sidebar/' ], { queryParams: { id: -1, search: this.searchForm.value.searchtxt } });

        // const param: any = {};
        // param.search = this.searchForm.value.searchtxt;
        // console.log(param);
        // this.productService.searchProduct(param).subscribe(data => {
        //     console.log(data);
        // });

    }

}
