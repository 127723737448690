import { Component, Input, OnInit } from '@angular/core';
import {Changepassword, Locback} from '../../../classes/product';
import {FormBuilder, FormControl, FormGroup, Validators,FormsModule} from "@angular/forms";
import { AuthenticationService } from "../../../services/authentication.service";
import {Router} from "@angular/router";
import {Location} from '@angular/common';
import {ProductService} from "../../../services/product.service";
import { ToastrService } from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';



@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  @Input() changepasswords: Changepassword;
  @Input() locback: Locback;

  public changePasswordForm: FormGroup;

  public submitted = false;

  public wrongPassword = false;

  public Removewrgpassvar = false;

  public visibleoldpass:boolean = true;

  public visiblenewpass:boolean = true;

  public visiblecnfpass:boolean = true;


  public changeoldtype : boolean = true;

  public changenewtype : boolean = true;

  public changecnftype : boolean = true;


  constructor(
    public fb: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    public location: Location,
    private toastr: ToastrService,
    public FormsModule: FormsModule,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initFormGroup();

    // console.log("changepasswords PAGE", this.changepasswords);
    // console.log(this.changepasswords.emailid);
    // console.log(this.changepasswords.oldpassword);
    //
    // console.log("locback", this.locback);
    // console.log("locback1", this.locback.loc);

    if(!this.changepasswords.oldpassword){
      // console.log("Password empty");
      if(!localStorage["customerid"]){
        this.router.navigate(['/home']);
      }
    }

    // Change Password detais display start
    this.changePasswordForm.patchValue({
      userid: this.changepasswords.emailid,
      oldpassword: this.changepasswords.oldpassword,
      newpassword: "",
      retypepassword: "",
    });
    // Change Password detais display end
  }
  /**
   Change Password form group initizise
   */
  initFormGroup() {
    this.changePasswordForm = this.fb.group({
        userid: new FormControl("", ),
        oldpassword: new FormControl("", Validators.compose([Validators.required,Validators.minLength(6),])),
        newpassword: new FormControl("",Validators.compose([Validators.required,Validators.minLength(6),])),
        retypepassword: new FormControl("",Validators.compose([Validators.required,])),
      },{ validator: this.mustmatch("newpassword", "retypepassword") }
    );
  }

  /**
   User Registration form validation
   */
  get chpass() {
    return this.changePasswordForm.controls;
  }

  /*  ---------------------------------------------------------   
      -----------Password and confirm password ----------------   
      ---------------------------------------------------------   */

  mustmatch(para_password: string, para_retypepassword: string) {
    return (formGroup: FormGroup) => {
      const temp_password = formGroup.controls[para_password];
      const temp_retypepassword = formGroup.controls[para_retypepassword];
      if (temp_retypepassword.errors && !temp_retypepassword.errors.mustmatch) {
        return;
      }
      if (temp_password.value !== temp_retypepassword.value) {
        temp_retypepassword.setErrors({ mustmatch: true });
      } else {
        temp_retypepassword.setErrors(null);
      }
    };
  }

  /*//////////////////////////////
    function to hide the error msg with timedelay
  /////////////////////////////////////  */

  hideErrorWithDelay() {
    setTimeout(() => {
      this.wrongPassword = false;
    }, 5000); // Set the delay to 5 seconds (5000 milliseconds)
  }

  /**
   Submit Change Password form
   */

  SubmitChangePwd() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    // console.log('form value',this.changePasswordForm.value);
    // this.submitted = true;
    // localStorage.clear();
    const param: any = {};
    // param.emailid = this.changePasswordForm.value.userid;
    // param.random = this.changePasswordForm.value.oldpassword;
    // param.password = this.changePasswordForm.value.newpassword;
    // // param.retypepassword = this.changePasswordForm.value.retypepassword;
    param.emailid = btoa(this.changePasswordForm.value.userid);
    param.password = btoa(this.changePasswordForm.value.newpassword);
    param.random = btoa(this.changePasswordForm.value.oldpassword);
    // console.log('param',param);
    this.spinner.show();
    this.authenticationService.changePasswordSave(param).subscribe((datafetch) => {
        // console.log("Datafetch", datafetch);
        // console.log("locback",this.locback);
        // if (this.locback.loc == "chgpwd") {
        //   console.log("if chgpwd",this.locback.loc);
        //    this.location.back();
        // }else
      this.submitted = false;
        if(datafetch == "fail"){
          this.spinner.hide();
          this.wrongPassword=true;
          this.hideErrorWithDelay();
          // alert("password wrong");
          // console.log("wrong pass true",this.wrongPassword);
        } else if(datafetch=="success"){
          this.spinner.hide();
          // console.log("Else PART");
          this.toastr.success("Password changed successfully");
          // this.location.back();
          // this.location.reload();

          // console.log("locback", this.locback);
          // console.log("locback111", this.locback.loc);
          if(this.locback.loc && this.locback.loc != ''){
            this.router.navigate(['/home']);
          }else {
            this.router.navigate(["/pages/login"]);
            location.reload();
          }
        }
      });
  }

  /**
   clear Change Password form
   */

  clearChangePwd() {
    this.submitted = false;
    this.changePasswordForm.patchValue({
      oldpassword: "",
      // userid: this.changepasswords.emailid,
      newpassword: "",
      retypepassword: "",
    });
  }

  Removewrgpass(){
    this.Removewrgpassvar=true;
  }

   //view and hide old password

   viewoldpass(){
    this.visibleoldpass=!this.visibleoldpass;
    this.changeoldtype=!this.changeoldtype;
}

 //view and hide new password

  viewnewpass(){
    this.visiblenewpass=!this.visiblenewpass;
    this.changenewtype=!this.changenewtype;
  }

   //view and hide confirm password

   viewcnfpass(){
    this.visiblecnfpass=!this.visiblecnfpass;
    this.changecnftype=!this.changecnftype;
  }

}
