<div class="navbar">
  <a href="javascript:void(0)" (click)="leftMenuToggle()">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </a>
  <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()"
                                                         (mouseover)="onHover(false)"></a>
    <nav id="unset">
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-start"><i class="fa fa-angle-left pe-2" aria-hidden="true"></i> Back
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
<!--        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.children ? true : false)">-->
        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.sideproductdetails ? true : false)">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'"
                (click)="toggletNavActive(menuItem)">
<!--            <a href="javascript:void(0)"-->
<!--                (click)="toggletNavActive(menuItem)">-->
<!--               {{menuItem.title}}-->
               {{menuItem.ApplianceName}}
<!--                {{menuItem.megaMenu}}-->
<!--               <span class="sub-arrow"  *ngIf="menuItem.children"></span>-->
               <span class="sub-arrow"  *ngIf="menuItem.sideproductdetails"></span>
            </a>
            <!-- Link -->
            <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link'">
<!--               {{menuItem.title}}-->
               {{menuItem.ApplianceName}}
<!--               <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
               <span class="sub-arrow" *ngIf="menuItem.sideproductdetails"></span>
            </a>
            <!-- External Link -->
<!--            <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'">-->
            <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'">
<!--               {{menuItem.title}}-->
               {{menuItem.ApplianceName}}
<!--               <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
               <span class="sub-arrow" *ngIf="menuItem.sideproductdetails"></span>
            </a>
            <!-- External Tab Link -->
            <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
<!--               {{menuItem.title}}-->
               {{menuItem.ApplianceName}}
<!--               <span class="sub-arrow" *ngIf="menuItem.children"></span>-->
               <span class="sub-arrow" *ngIf="menuItem.sideproductdetails"></span>
            </a>
            <!-- 2nd Level Menu -->
            <ul [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''" [class.opensub1]="menuItem.active" *ngIf="menuItem.sideproductdetails">
<!--            <ul  [class.opensub1]="menuItem.active" *ngIf="menuItem.sideproductdetails">-->

              <!-- Simple Menu Start-->
              <ng-container *ngIf="!menuItem.megaMenu">
<!--              <ng-container >-->
<!--                <li *ngFor="let childrenItem of menuItem.children">-->
                <li *ngFor="let childrenItem of menuItem.sideproductdetails">
                  <!-- Link -->
                  <a class="has-submenu" [routerLink]="childrenItem.path" *ngIf="childrenItem.type === 'link'">
<!--                  <a class="has-submenu" [routerLink]="childrenItem.path"-->
<!--                     (click)="toggletNavActive(childrenItem)">-->
<!--                     {{childrenItem.title}}-->
                     {{childrenItem.WebProductName}}
<!--                     {{childrenItem.path}}-->
<!--                     {{childrenItem.type}}-->
                  </a>
                  <!-- External Link -->
                  <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'">
<!--                  <a href="{{ !childrenItem.type ? null : childrenItem.path }}" >-->
<!--                    {{childrenItem.title}}-->
                    {{childrenItem.WebProductName}}
                  </a>
                  <!-- External Tab Link -->
                  <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank" *ngIf="childrenItem.type === 'extTabLink'">
<!--                  <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank" >-->
<!--                     {{childrenItem.title}}-->
                     {{childrenItem.WebProductName}}
                  </a>
                  <!-- 3rd Level Menu -->


<!--                    <ul  *ngIf="childrenItem.Sidecategorydetails">-->

<!--                        <li *ngFor="let childrenSubItem of childrenItem.Sidecategorydetails">-->
<!--                                                        {{childrenSubItem.WebCategoryName}}-->
<!--                        </li>-->
<!--                    </ul>-->


<!--                    <div  *ngIf="childrenItem.Sidecategorydetails">-->

<!--                        <div *ngFor="let childrenSubItem of childrenItem.Sidecategorydetails">-->
<!--                            <a href="">{{childrenSubItem.WebCategoryName}}</a>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    {{childrenItem.WebProductid}}-->
<!--                    {{childrenItem.WebProductName}}-->
<!--                    {{childrenItem.Sidecategorydetails}}-->
<!--                    {{childrenItem}}-->


<!--                  <ul *ngIf="childrenItem.Sidecategorydetails">-->
                  <ul >

<!--                      {{childrenItem.WebProductid}}-->
<!--                      {{childrenItem.WebProductName}}-->
<!--                      {{childrenItem.Sidecategorydetails}}-->

<!--                                          {{childrenItem.Sidecategorydetails}}-->

<!--                                          {{childrenItem}}-->


<!--                      {{'dfewrerewrewrerewrew'}}-->

<!--                  <ul *ngIf="childrenItem.children">-->
<!--                    <li *ngFor="let childrenSubItem of childrenItem.children">-->
                    <li *ngFor="let childrenSubItem of childrenItem.Sidecategorydetails">
                        <!-- Link -->
                        <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
<!--                        <a [routerLink]="childrenSubItem.path" >-->
<!--                           {{childrenSubItem.title}}-->
                           {{childrenSubItem.WebCategoryName}}
                        </a>
                        <!-- External Link -->
                        <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
<!--                          {{childrenSubItem.title}}-->
                          {{childrenSubItem.WebCategoryName}}
                        </a>
                        <!-- External Tab Link -->
                        <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
<!--                           {{childrenSubItem.title}}-->
                           {{childrenSubItem.WebCategoryName}}
                        </a>
                        <!-- 4th Level Menu -->
<!--                        <ul *ngIf="childrenSubItem.children">-->
                        <ul *ngIf="childrenSubItem.Sidecategorydetails">
<!--                          <li *ngFor="let childrenSubSubItem of childrenSubItem.children">-->
                          <li *ngFor="let childrenSubSubItem of childrenSubItem.Sidecategorydetails">
                              <!-- Link -->
                              <a [routerLink]="childrenSubSubItem.path" *ngIf="childrenSubSubItem.type === 'link'">
<!--                                 {{childrenSubSubItem.title}}-->
                                 {{childrenSubSubItem.WebCategoryName}}
                              </a>
                              <!-- External Link -->
                              <a href="{{ childrenSubSubItem.path }}" *ngIf="childrenSubSubItem.type === 'extLink'">
<!--                                {{childrenSubSubItem.title}}-->
                                {{childrenSubSubItem.WebCategoryName}}
                              </a>
                              <!-- External Tab Link -->
                              <a href="{{ childrenSubSubItem.path }}" target="_blank" *ngIf="childrenSubSubItem.type === 'extTabLink'">
<!--                                 {{childrenSubSubItem.title}}-->
                                 {{childrenSubSubItem.WebCategoryName}}
                              </a>
                              <!-- 5th Level Menu -->
<!--                              <ul *ngIf="childrenSubSubItem.children">-->
                              <ul *ngIf="childrenSubSubItem.children">
<!--                                <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">-->
                                <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">
                                    <!-- Link -->
                                    <a [routerLink]="childrenSubSubSubItem.path" *ngIf="childrenSubSubSubItem.type === 'link'">
<!--                                       {{childrenSubSubSubItem.title}}-->
                                       {{childrenSubSubSubItem.title}}
                                    </a>
                                    <!-- External Link -->
                                    <a href="{{ childrenSubSubSubItem.path }}" *ngIf="childrenSubSubSubItem.type === 'extLink'">
<!--                                      {{childrenSubSubSubItem.title}}-->
                                      {{childrenSubSubSubItem.title}}
                                    </a>
                                    <!-- External Tab Link -->
                                    <a href="{{ childrenSubSubSubItem.path }}" target="_blank" *ngIf="childrenSubSubSubItem.type === 'extTabLink'">
<!--                                       {{childrenSubSubSubItem.title}}-->
                                       {{childrenSubSubSubItem.title}}
                                    </a>
                                </li>
                              </ul>
                          </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ng-container>
              <!-- Simple Menu End-->

              <!-- Mega Menu Start-->
<!--              <ng-container *ngIf="menuItem.megaMenu">-->
              <ng-container *ngIf="menuItem.megaMenu">
              <li>
                <div class="row m-0">
<!--                  <div class="col-xl-12" *ngFor="let childrenItem of menuItem.children">-->
                  <div class="col-xl-12" *ngFor="let childrenItem of menuItem.sideproductdetails">
                    <div class="link-section">
<!--                      <h5>{{childrenItem.title}}</h5>-->
                      <h5>{{childrenItem.WebProductName}}</h5>
                      <ul>
<!--                        <li *ngFor="let childrenSubItem of childrenItem.children">-->
                        <li *ngFor="let childrenSubItem of childrenItem.Sidecategorydetails">
<!--                        <li *ngFor="let childrenSubItem of childrenItem.sideproductdetails">-->
                           <!-- Link -->
                            <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
<!--                               {{childrenSubItem.title}}-->
                               {{childrenSubItem.WebCategoryName}}
                            </a>
                            <!-- External Link -->
                            <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
<!--                              {{childrenSubItem.title}}-->
                              {{childrenSubItem.WebCategoryName}}
                            </a>
                            <!-- External Tab Link -->
                            <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
<!--                               {{childrenSubItem.title}}-->
                               {{childrenSubItem.WebCategoryName}}
                            </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-12">
                      <a [routerLink]="['/home/one']" class="mega-menu-banner"><img src="" alt="" class="img-fluid"></a>
                  </div>
                </div>
              </li>
              </ng-container>
              <!-- Mega Menu End-->
            </ul>
          </li>
      </ul>
    </nav>
  </div>
</div>

