<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container-fluid">
      <div class="row">

        <div class="col-xl-9 col-lg-9 col-md-12">
<!--        <div class="col-lg-6">-->
          <div class="header-contact">

<!--              details-->
<!--              :-->
<!--              "How to purchase"-->
<!--              shopcloseday-->
<!--              :-->
<!--              "Sunday Closed"-->
<!--              shopemailid-->
<!--              :-->
<!--              "rathnafanhouse@gmail.com"-->
<!--              shopphonenumber-->
<!--              :-->
<!--              " 81000 09797 / +044 28151266 / 28153068"-->
<!--              shoptime-->
<!--              :-->
<!--              "Shop Time :10.00AM to 8.30PM "-->

              <ul *ngFor="let sundayopenclosetxt of getSundayopenclosedText">
<!--              <li>Welcome to RFH</li>-->
<!--              <li><i class="fa fa-phone" aria-hidden="true"></i> 81000 09797 / +044 28151266 / 28153068</li>-->
                  <li><i class="fa fa-phone" aria-hidden="true"></i><a href="tel:04428151266"> {{sundayopenclosetxt.shopphonenumber}} </a></li>
<!--              <li><i class="fa fa-phone" aria-hidden="true"></i> 81000 09797 / +044 28151266 / 28153068</li>-->
<!--              <li>rathnafanhouse@gmail.com</li>-->
                  <li><a href="mailto:rathnafanhouse@gmail.com">{{sundayopenclosetxt.shopemailid}}</a></li>
                <li><a href="assets/images/RFH How to Purchase.pdf" target="_blank">How to Purchase</a></li>
                  <!-- <li ><a href="">{{sundayopenclosetxt.details}}</a></li> -->
<!--                <li> &nbsp; &nbsp;<a href="">Shop Time :10.00AM to 8.30PM</a> </li>-->
                <li><a href="">{{sundayopenclosetxt.shoptime}}</a> </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 text-end">
          <ul class="header-dropdown">
<!--            <li><a href="">Shop Time :10.00AM to 8.30PM</a> </li>   -->
<!--            <li>-->
<!--              Cart <img src="assets/images/icon/cart.png" alt="" style="width:15px;margin-right:8px" class="img-fluid">-->
<!--            </li>-->
<!--            <li>-->
<!--              <a href="">Register/Login</a>-->
<!--            </li>-->
<!--              <li>Sunday Closed</li>-->
              <li *ngFor="let sundayopenclosetxt1 of getSundayopenclosedText" class="blink_me">{{sundayopenclosetxt1.shopcloseday}}</li>
<!--              <li *ngFor="let sundayopenclosetxt1 of getSundayopenclosedText" >{{sundayopenclosetxt1.shopcloseday}}</li>-->
              <!-- <li class="compare">
                  <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
              </li> -->
              <li class="mobile-wishlist">
                  <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>
              </li>
              <li class="ps-2" *ngIf="!cusId || cusId == '' || profileUsername=='' "><a [routerLink]="['/pages/login']" data-lng="en"> Register/Login </a></li>
              <li *ngIf="cusId && cusId != ''" class="onhover-dropdown mobile-account ps-2">
<!--                  <i class="fa fa-user" aria-hidden="true"></i> My account-->
                  <i class="fa fa-user" aria-hidden="true"></i> {{profileUsername}}
                  <ul class="onhover-show-div">
<!--                      <li>-->
<!--                          <a [routerLink]="['/pages/login']" data-lng="en">-->
<!--                              Login-->
<!--                          </a>-->
<!--                      </li>-->
                      <li>
                          <a routerLink="/home/myinfo">My Account</a>
                      </li>
                      <li>
                          <a routerLink="/home/myorders">My Orders</a>
                      </li>
                      <li>
                          <a routerLink="/pages/passwordchange/chgpwd">Change Password</a>
                      </li>
                      <li>
                          <a data-lng="es" (click)="onLogOut()">
                              Logout
                          </a>
                      </li>
                  </ul>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

<!--    <marquee>TESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTESTTEST</marquee>-->
    <marquee style="font-size: large; color: blue; margin-bottom: -20px !important;">{{this.getmarqueeText}}</marquee>
<!--  <div class="container-fluid">-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 pad_set">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
<!--              <a routerLink="/home/fashion" id="headerlogo">-->
              <a routerLink="/home" id="headerlogo">
                <img src="assets/images/logo.png" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
                <div class="col-12">
<!--                    <form action="">-->
                    <form [formGroup] = "searchForm">
                        <div class="p-1 bg-light rounded rounded-pill shadow-sm mb-4 cust_search">
                            <div class="input-group">
<!--                                <input type="search"  placeholder="What're you searching for?" class="form-control border-0 bg-light" name="searchtxt" aria-describedby="button-addon1" class="form-control border-0 bg-light">-->
                                <input type="search" placeholder="What're you searching for?" class="form-control border-0 bg-light" name="searchtxt" aria-describedby="button-addon1" formControlName="searchtxt" >
                                <div class="input-group-append">
                                    <button id="button-addon1" type="submit" class="btn btn-link text-primary" (click)="searchText()"><i class="fa fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
